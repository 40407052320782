(function() {
    'use strict';

    angular.module("mfgApp")
        .controller("RogueCtrl", rogueController);

    rogueController.$inject = ["$scope", "$stateParams", "$uibModal", "$location", "addBulkSerialNumbers", "mfgApi",
        "Notification", "routesConstant"];

    function rogueController($scope, $stateParams, $uibModal, $location, addBulkSerialNumbers, mfgApi, Notification,
                             routesConstant) {
        $scope.$parent.page = $scope.$parent.page || {};
        $scope.$parent.page.title = "Manage Hardware Device";

        $scope.allRogueUnits = [];
        $scope.newSerialNumbers = [];
        $scope.aflAllAssets=[];
        $scope.aflAssetProductCodes=[];
        $scope.assetType="OLTS";
        $scope.assetName="Rogue";
        $scope.MFG = routesConstant.MFG;

        getRogueUnits();
        getAFLAssetProductCodes();

        function getRogueUnits() {
            $scope.allRogueUnits.length = 0;
            mfgApi.getAllRogueUnits()
                .success(function (data) {
                    var units = data.rogueUnits;
                    _.each(units, function (rogueUnit) {
                        var formattedDate = moment(rogueUnit.createdOn).format("MM/DD/YYYY");
                        rogueUnit['new'] = false;
                        rogueUnit.createdOn = formattedDate;
                        rogueUnit.searchIndex = rogueUnit.serialNumber + " " + rogueUnit.createdBy + " "
                            + rogueUnit.createdOn;
                        $scope.allRogueUnits.push(rogueUnit);
                    });
                })
                .error(function (err) {
                    Notification.error("Something went wrong. " + err.message);
                });
        }

        function getAFLAssetProductCodes(){
            mfgApi.getAllAFLAssetProductCodes().success(function(data){
                _.each(data.aflAssets, function(aflAsset){
                    $scope.aflAllAssets.push(aflAsset);
                    $scope.aflAssetProductCodes.push(aflAsset.productCode);
                });
            })
        }

        $scope.refresh = function(){
            getRogueUnits();
        };

        $scope.showAFLAssetProductCodes = function() {
            showAssetInGrid($scope.aflAllAssets);
        };

        function showAssetInGrid(aflAssetsInfo){
            $uibModal.open({
                size: "md",
                templateUrl: 'aflAssetProductCodeInfoModal.html',
                controller: ["$scope", "aflAllAssets", function($scope, aflAllAssets){
                    $scope.gridOptions = {
                        columnDefs: [],
                        rowData: [],
                        enableSorting: true,
                        enableFilter: true,
                        enableColResize: true,
                        angularCompileRows: true,
                        onGridReady: function() {
                            $scope.gridOptions.api.setRowData(aflAllAssets);
                        }

                    };

                    var aflAssetProductCodesFields = [
                        {
                            headerName: "Product Code",
                            field: 'productCode'
                        }, {
                            headerName: "AFL Model",
                            field: 'aflModel'
                        }, {
                            headerName: "Asset Type",
                            field: 'assetType'
                        }, {
                            headerName: "Asset Name",
                            field: 'assetName'
                        }, {
                            headerName: "Display Name",
                            field: 'displayName'
                        }
                    ];

                    _.each(aflAssetProductCodesFields, function (field) {
                        $scope.gridOptions.columnDefs.push(field);
                    });
                }],
                resolve: {
                    aflAllAssets: function () {
                        return  aflAssetsInfo;
                    }
                }
            });
        }

        $scope.data = {};
        $scope.importRogueUnits = function(file){
            $scope.uploadedFile = undefined;
            $scope.uploadErrors = [];
            mfgApi.uploadRogueImport(file)
                .success(function(data){
                    $scope.uploadedFile = data.path;
                    console.log("Uploaded file "+$scope.uploadedFile);
                    getRogueUnits();
                })
                .error(function (err) {
                    Notification.error("Something went wrong. " + err.message);
                });
        };

        $scope.importProductCode = function(file){
            $scope.uploadedProductCodeFile = undefined;
            $scope.uploadErrors = [];
            mfgApi.uploadProductCodeImport(file)
                .success(function(data){
                    $scope.uploadedProductCodeFile = data.path;
                    console.log("Uploaded file "+$scope.uploadedProductCodeFile);
                    getRogueUnits();
                })
                .error(function (err) {
                    Notification.error("Something went wrong. " + err.message);
                });
        };

        function getAssetTypeAndName(productCode){
            mfgApi.getAssetTypeAndName().success(function(data){
                $scope.assetType = data.assetType;
                $scope.assetName = data.assetName;
            })
        }

        $scope.filter = {
            searchText: '',
            searchTextRegex: '',
            updateFilter: function () {
                if ($scope.filter.searchText) {
                    $scope.filter.searchTextRegex = new RegExp($scope.filter.searchText, 'i');
                }
            },
            listFilter: function (unit) {
                // no filter is set
                if (!$scope.filter.searchText || !$scope.filter.searchTextRegex) {
                    return true;
                    // don't hide blank new entries
                } else if (unit['new'] && !unit.serialNumber) {
                    return true;
                    // filter old + new entries with serialNumber
                } else {
                    return 	$scope.filter.searchTextRegex.test(unit.serialNumber) ||
                        $scope.filter.searchTextRegex.test(unit.createdBy) ||
                        $scope.filter.searchTextRegex.test(unit.createdOn);
                }
            }
        };

        $scope.addRogueUnit = function (newUnit) {
            $scope.inserted = newUnit || {
                serialNumber: "",
                createdBy: "",
                createdOn: moment().format('L'),
                'new': true
            };
            $scope.newSerialNumbers.push($scope.inserted);
            $scope.allRogueUnits.unshift($scope.inserted);
        };

        $scope.addBulkRogueUnit = function (rogueUnit) {
            addBulkSerialNumbers.open(rogueUnit.serialNumber).then(function (rogueSerialNumbers) {
                _.each(rogueSerialNumbers, function (rogueSerialNumberObj) {
                    $scope.addRogueUnit(rogueSerialNumberObj);
                });
            });
        };

        $scope.deleteRogueUnit = function (rogueUnit) {
            $uibModal.open({
                size: "md",
                resolve: {
                    rogueUnit: function () {
                        return rogueUnit;
                    }
                },
                templateUrl: 'confirmDeleteModal.html',
                controller: ["$scope", "rogueUnit", function ($scope, rogueUnit) {
                    $scope.fullName = rogueUnit.serialNumber;
                    $scope.title = "Delete Rogue Carrier?";
                    $scope.message = "Are you sure you want to delete the Rogue Carrier " + $scope.fullName + "?";
                }]
            }).result.then(function () {
                var idxExistingSerial = $scope.allRogueUnits.indexOf(rogueUnit);
                $scope.allRogueUnits.splice(idxExistingSerial, 1);
                if (rogueUnit['new']) {
                    var idxNewSerial = $scope.newSerialNumbers.indexOf(rogueUnit);
                    $scope.newSerialNumbers.splice(idxNewSerial, 1);
                    Notification.success("Rogue carrier serial number " + rogueUnit.serialNumber + " removed");
                } else {
                    mfgApi.deleteRogueCarrier(rogueUnit.serialNumber)
                        .success(function () {
                            Notification.success("Rogue carrier serial number " + rogueUnit.serialNumber + " deleted");
                        })
                        .error(function (err) {
                            Notification.error("Serial Number was not deleted, " + err.message);
                        });
                }
            });
        };
        /* Rules: Checkout https://regex101.com/ to test.
         * The rules/requirements are:
            1-2 characters = alphanumeric
            3-4 characters = 01 through 53
            5-6 characters = the product code and will be “UD” or "UC"
            last there characters = Numeric between 001 and 999.
            Any character could be upper or lower case
         *
         * */
        function validateSerialNumber(serialNumber) {
            var toolType = null;
            //OLD: ^\d[A-Z]([0-5]{1}|0[1-9]|[12]\d|5[0-2])[A-Z]{2}[0-9]{3}$

            //^([0-9a-zA-Z]{2})([1-4][0-9]|0[1-9]|5[0-3])([uU][cCdD])([0-9]{3})$

            //Break down of the regex
            //	([0-9a-zA-Z]{1})  = any 2 alpha or number combos
            //	([1-4][0-9]|0[1-9]|5[0-3])  = 10-49 OR 01-09 OR 50-53
            //	[A-Z]{2} = Any 2 chars
            //	[0-9]{3} = any 3 digits

            //       (    1-2       )(       3-4              )[  5-6 ][  7-9 ]

            _.each($scope.aflAllAssets,function(asset){
                if(serialNumber.indexOf(asset.productCode)!= -1){
                    toolType = asset.assetType;
                }
            });

            if(toolType === "Inspection")
                return /^(2[a-zA-Z])([0-4][1-9]|5[0-2])(W[STZ])([0-9]{3})$/.test(serialNumber);
            if(toolType === "RogueHW")
                return /^(2[a-zA-Z])([0-4][1-9]|5[0-2])([uU][cCdD]|WM)([0-9]{3})$/.test(serialNumber);
            if(toolType === "OTDR")
                return /^(2[a-zA-Z])([0-4][1-9]|5[0-2])(V[NP-V])([0-9]{5})$/.test(serialNumber);
            if(toolType === "OLTS")
                return /^(2[a-zA-Z])([0-4][1-9]|5[0-2])(U[E-H]|V[E-HJ-M]|W[KNPR])([0-9]{3})$/.test(serialNumber);
        }

        $scope.saveRogueCarrierNum = function () {
            var validSerialNumbers = [];
            var validatedAllSerialNumbers = true;
            _.each($scope.allRogueUnits, function (unit) {
                if (unit['new']) {
                    if (validateSerialNumber(unit.serialNumber)) {
                        validSerialNumbers.push(unit.serialNumber);
                    } else {
                        Notification.error("Serial number was not saved. Wrong format");
                        validatedAllSerialNumbers = false;
                        return;
                    }
                }
            });
            if (validatedAllSerialNumbers) {
                var request = {};
                request.ids = angular.copy(validSerialNumbers);
                mfgApi.saveRogueCarrier(request)
                    .success(function (rsp) {
                        var duplicates = "";
                        var totalNumberSaved = validSerialNumbers.length - rsp.duplicates.length;
                        _.each(rsp.duplicates, function (duplicateSerial) {
                            duplicates += duplicateSerial + " ";
                        });
                        if (rsp.duplicates.length === 0) {
                            Notification.success(totalNumberSaved + " Rogue carrier serial number saved.");
                        } else {
                            Notification.warning(totalNumberSaved + " Rogue carrier serial number saved, discarded "
                                + rsp.duplicates.length + " duplicates: " + duplicates);
                        }
                        getRogueUnits();
                    })
                    .error(function (err) {
                        Notification.error("Serial number was not saved. " + err.message);
                    });
            }
        };
    }

})();

